import { env } from 'cms-suppliers/lib/env';
import md5 from 'js-md5';
import { Response } from 'node-fetch';
import { Suppliers, Users, Weddings } from '@bridebook/models';
import {
  ConversationOptions,
  CoupleChatUserOptions,
  SupplierChatUserOptions,
} from '@bridebook/toolbox/src/inbox/types';
import {
  getTalkJsCoupleRole,
  getTalkJsSupplierRole,
  isCoupleRole,
} from '@bridebook/toolbox/src/inbox/utils';
import { Conversation, User } from './webhook.types';

/**
 * Returns a user object used to create or update a TalkJS user for couple
 * IMPORTANT! Do not expose this function to the client directly.
 *
 * @param weddingId
 */
export const getCoupleChatUserOptions = async ({
  weddingId,
}: {
  weddingId: string;
}): Promise<CoupleChatUserOptions | null> => {
  const wedding = await Weddings._.getById(weddingId).get();

  // Handle enquiry widget case
  if (!wedding?.id) return null;

  const {
    id,
    partners,
    photoCover,
    users: userIds,
    l10n: { country },
  } = wedding;
  const name = getCoupleNames(partners);

  const photoUrl = photoCover?.path
    ? `https://bridebook-users.imgix.net/${photoCover.path}?fit=crop&crop=faces&auto=format,compress,enhance&facepad=3&ar=1:1`
    : undefined;

  // Include emails of all wedding collaborators
  const usersData = await Promise.all(userIds.map((userId) => Users._.getById(userId).get()));
  const userEmails = usersData.map((user) => user.contacts?.email).filter(Boolean);

  return {
    id,
    name,
    role: getTalkJsCoupleRole(country),
    email: userEmails,
    photoUrl: photoUrl || null,
    welcomeMessage: null,
    custom: {
      userId: userIds[0],
    },
  };
};

/**
 * Returns a user object used to create or update a TalkJS user for supplier
 * IMPORTANT! Do not expose this function to the client directly.
 */
export const getSupplierChatUserOptions = async ({
  supplierId,
}: {
  supplierId: string;
}): Promise<SupplierChatUserOptions> => {
  const supplierRef = Suppliers._.getById(supplierId);
  const pSupplier = supplierRef.get();
  const pThumbnail = supplierRef.getThumbnail();
  const pSupplierAdmin = supplierRef.getAdmin();

  const [supplier, thumbnail, supplierAdmin] = await Promise.all([
    pSupplier,
    pThumbnail,
    pSupplierAdmin,
  ]);
  const userId = supplier?.users?.[0];
  const photoUrl = thumbnail?.path ? `https://images.bridebook.com/${thumbnail.path}` : '';
  const countryCode = supplier.address.country;

  /**
   * In order to be able to receive native TalkJS email notification:
   * - supplier must be registered
   * - supplier must not be PPE
   * - supplier must not have bad debt
   * Suppliers with PPE or bad debt will receive email notifications from customer.io.
   */
  const isRegistered = supplier.users?.length > 0;
  const isPayPerEnquiry = Boolean(supplierAdmin.payPerEnquiry);
  const canReceiveTalkJsEmails = isRegistered && !isPayPerEnquiry && !supplierAdmin.hasBadDebt?.cms;

  // IMPORTANT! Use real supplier emails only in production
  const supplierEmail = canReceiveTalkJsEmails
    ? env.LIVE
      ? [supplier?.contacts?.email || '']
      : ['devsupplier@bridebook.co.uk']
    : [''];

  return {
    id: supplier.id,
    name: supplier.name,
    role: getTalkJsSupplierRole(countryCode),
    email: supplierEmail,
    photoUrl: photoUrl || '',
    custom: {
      userId: userId || '',
      publicId: supplier.publicId,
      type: supplier.type[0],
      city: supplier.address.city,
      adminArea0: supplier.address.adminArea[0] || '',
      adminArea1: supplier.address.adminArea[1] || '',
      country: countryCode,
      // Photo avatar used in email notification
      photoUrl:
        photoUrl || `https://bridebook-images.imgix.net/assets/supplier/img-custom-supplier.jpg`,
      seoUrlSlug: supplier.seo?.urlSlug ?? '',
    },
  };
};

/**
 * Returns an object used to create a conversation in TalkJS via REST API
 * between a couple and a supplier
 */
export const getChatConversationOptions = ({
  supplierId,
  weddingId,
}: {
  supplierId: string;
  weddingId: string;
}): ConversationOptions => ({
  id: getConversationId({ supplierId, weddingId }),
  participants: [supplierId, weddingId],
  custom: {},
});

/**
 * Returns a conversation id created as a hash from supplier id and wedding id
 *
 * @param supplierId
 * @param weddingId
 */
export const getConversationId = ({
  supplierId,
  weddingId,
}: {
  supplierId: string;
  weddingId: string;
}) => md5(`${supplierId}-${weddingId}`);

const getCoupleNames = (partners: string[]) => {
  if (partners.length > 0) {
    return `${partners[0] || 'Partner'} & ${partners[1] || 'Partner'}`;
  }
  return 'The Couple';
};

/**
 * Returns response if the status was 200, throws an error otherwise
 * @param response
 */
export const checkResponseStatus = async (response: Response) => {
  if (response.status !== 200) {
    const msg = await response.text();
    throw new Error(msg);
  }
  return response;
};

/**
  Conversation participants object should only contain weddingId and supplierId,
  so removing sender.id from it, we end up with recipient id..
*/
export const extractRecipientId = (data?: {
  conversation: Conversation;
  sender: User;
}): string | undefined =>
  Object.keys(data?.conversation.participants || []).filter((id) => id !== data?.sender.id)[0];

/**
 * Retrieves the lead identifier from conversation data.
 *
 * This function extracts a lead ID from the conversation's custom properties (supplierWeddingId or
 * enquiryId) which are legacy identifiers, or falls back to the sender's ID if no custom property
 * exists, which equals to weddingId.
 */
export const getLeadId = (data?: {
  conversation: Conversation;
  sender: User;
}): string | undefined =>
  data?.conversation.custom?.supplierWeddingId ||
  data?.conversation.custom?.enquiryId ||
  isCoupleRole(data?.sender.role)
    ? data?.sender.id
    : extractRecipientId(data);
