import { AutomaticMessageOrigin } from '@bridebook/models/source/models/Weddings/Suppliers.types';
import { authenticatedPOST } from '@bridebook/toolbox/src/api/auth/authenticated-fetch';
import { CustomMessageBody } from '@bridebook/toolbox/src/inbox/types';
import { SentryMinimal } from '@bridebook/toolbox/src/sentry';
import {
  InitTalkConversationBody,
  InitTalkConversationResponse,
} from 'pages/api/inbox/init-talk-conversation';
import { ApiEndpoint } from 'lib/api/api-endpoint';
import { getEnquiryFormIntents } from 'lib/enquiries/selectors';
import { getDetailedIntents } from 'lib/enquiries/utils/get-detailed-intents';
import { getI18n } from 'lib/i18n/getI18n';
import { getCoupleChatUserOptions } from 'lib/inbox/selectors';
import { CreateEnquiryResponse } from 'lib/onboarding-new/types';
import { getSupplierCalendarLink, isSupplierVenue } from 'lib/supplier/utils';
import { IApplicationState } from 'lib/types';
import { getWeddingProfileId } from 'lib/weddings/selectors';

export const sendInboxMessageForEnquiry = async (
  { supplier, message }: Pick<CreateEnquiryResponse, 'supplier' | 'message'>,
  state: IApplicationState,
): Promise<{ inboxMessageSent: boolean; supplierId: string }> => {
  const coupleUserOptions = getCoupleChatUserOptions(state);
  const profileId = getWeddingProfileId(state);

  if (coupleUserOptions) {
    const i18n = getI18n();
    const isVenue = isSupplierVenue(supplier);
    const intent = getEnquiryFormIntents(state);

    // This temporary fix is to prevent asserStart failing when sending enquiry from search. Will be removed once LIVE-21855 is rolled out
    const calendarUrl = intent.showround && getSupplierCalendarLink(supplier);

    const intentValues = Object.values(getDetailedIntents(intent))
      .filter(({ checked, id }) => checked && id !== 'requestFastResponse')
      .map(({ label }) => `\n\n- ${label}`)
      .join('');
    const msgNewEnquiryFrom = `${i18n.t('enquiries:enquiryInboxMessage', {
      coupleName: coupleUserOptions.name,
    })}:`;
    const msgCoupleInterestedIn = i18n.t('enquiries:enquiryInboxMessage.intent');
    const coupleMessage = message;
    const venueText = `*${msgNewEnquiryFrom}*\n\n${coupleMessage}\n\n*${msgCoupleInterestedIn}:*\n\n${intentValues}`;
    const nonVenueText = `*${msgNewEnquiryFrom}*\n\n${coupleMessage}`;

    // LIVE-22056: This is temporary until we rollout showrounds (LIVE-21855). Enquiries via calendar will have their own intent / no intent
    const enquiryText = isVenue ? (calendarUrl ? nonVenueText : venueText) : nonVenueText;

    const messageObj: CustomMessageBody = {
      text: enquiryText,
      sender: coupleUserOptions.id,
      type: 'UserMessage',
      custom: {
        automaticOrigin: AutomaticMessageOrigin.ENQUIRY,
      },
    };

    // SEND MESSAGE TO TALK JS
    try {
      await authenticatedPOST<InitTalkConversationBody, InitTalkConversationResponse>(
        ApiEndpoint.inbox.initTalkConversation,
        {
          body: {
            weddingId: profileId,
            supplierId: supplier.id,
            messageObj,
          },
        },
      );
      return { inboxMessageSent: true, supplierId: supplier.id };
    } catch (e) {
      // Fail silently, we still want the enquiry to be sent even without TalkJS message
      SentryMinimal().captureMessage('[TALKJS] Error when sending enquiry message', {
        level: 'fatal',
        extra: { error: e },
      });
      return { inboxMessageSent: false, supplierId: supplier.id };
    }
  }
  return { inboxMessageSent: false, supplierId: supplier.id };
};
